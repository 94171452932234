import React from "react";
import styles from "./ScheduleSelector.module.css";

const ScheduleSelectorDropdown = ({ schedules, onSelectSchedule }) => {
  const handleSelectSchedule = (schedule) => {
    onSelectSchedule(schedule);
  };

  return (
    <div className={styles.dropdown}>
      {schedules.map((schedule) => (
        <div
          key={schedule.id}
          className={styles.dropdownItem}
          onClick={() => handleSelectSchedule(schedule)}
        >
          {schedule.attributes.store_name}
        </div>
      ))}
    </div>
  );
};

export default ScheduleSelectorDropdown;
