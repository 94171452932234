import React, { useEffect, useState } from "react";
import styles from "./ScheduleSelector.module.css";
import ScheduleSelectorDropdown from "./ScheduleSelectorDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

export default function ScheduleSelector({
  businessName,
  schedules,
  onSelectSchedule,
  selectedSchedule,
}) {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleOutsideClick = (event) => {
    if (!event.target.closest(`.${styles.container}`)) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleSelectSchedule = (schedule) => {
    onSelectSchedule(schedule);
    setShowDropdown(false);
  };

  return (
    <div className={styles.container} onClick={toggleDropdown}>
      <div className={styles.selectorContainer}>
        <div className={styles.div}>
          <div className={styles.selector}>
            <div className={styles.businessTitle}>{businessName}</div>

            {selectedSchedule && (
              <div className={styles.selectedSchedule}>
                {selectedSchedule.attributes.store_name}
              </div>
            )}
          </div>

          <FontAwesomeIcon icon={faCaretDown} className={styles.icon} />
        </div>
      </div>

      {showDropdown && (
        <ScheduleSelectorDropdown
          schedules={schedules}
          onSelectSchedule={handleSelectSchedule}
          selectedSchedule={selectedSchedule}
        />
      )}
    </div>
  );
}
